.sidebar {
  background: var(--clr-primary);
  /* background: #fff; */
  width: 100%;
  height: 100%;
}

.logo-section {
  margin: auto;
  padding-block: 0.5rem;
}

.sidebar-item {
  display: flex;
  padding: 0.8rem;
  align-items: center;
  /* color: var(--clr-theme); */
  color: #fff;
}

.sidebar-item:hover {
  color: var(--clr-primary);
}

.sidebar-item:active {
  background-color: #fff;
  color: #000;
}

.sidebar-content:hover {
  background-color: #fff;
  cursor: pointer;
}

.sidebar-title {
  font-size: 19px;
  padding-left: 0.5rem;
}

/* Hidden sidebar */

.sidebar-title-hide {
  display: none;
}

.logo-section-hide {
  padding-top: 5.5rem;
}

.collapsed-logo {
  transition: all 0.3s ease-in-out;
}

/* for making sidebar elements active */

.sidebar_item_active:active {
  background-color: #fff;
  color: #000;
}

/* .sidebar_item_active. */
