.notification {
  position: fixed;
  z-index: 1000;
  top: 5rem;
  right: 1rem;
  background-color: black;
  color: white;
  padding: 1.2rem;
  border-radius: 4px;
  width: max-content;
  max-width: 400px;
}
.notification--success {
  background-color: green;
}
.notification--danger {
  background-color: rgb(239, 52, 52);
}
.notification--alert {
  background-color: rgb(194, 93, 34);
}

.notification_slider {
  margin-top: 0.5rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.notification_content {
  width: 100%;
  text-overflow: ellipsis;
}

.notification_slide {
  height: 2px;
  width: 0%;
  background-color: white;
  animation: sliderslider 1s 1;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

@keyframes sliderslider {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
