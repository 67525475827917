@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --bg-primary: linear-gradient(180deg, #5551D2 0%, #5736AE 100%);
  ;
  /* --clr-primary: linear-gradient(129.99deg, #ffddb5 0%, #fff9ed 47.43%); */
  /* --clr-primary: linear-gradient(129.99deg, #2a3650 0%, #2a3650 47.43%); */
  /* --clr-primary: #2a3650; */
  --clr-primary: #5551D2;
  /* --clr-theme: #0071af; */
  --clr-theme: #5551D2;
  /* --clr-primary: #f6b884; */
  --roboto: "Roboto", sans-serif;
}

label {
  padding-bottom: 1%;
}

body {
  font-family: var(--roboto);
  background-color: var(--bg-primary);
  /* background: var(--clr-primary); */
  color: var(--fg-primary);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
}

i,
span,
a {
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
  letter-spacing: -0.03em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000000;
  margin: 0px;
  font-family: var(--roboto);
  font-display: swap;
  font-weight: 700;
}

h1 {
  font-size: 52px;
  line-height: 85px;
}

h2 {
  font-size: 42px;
  line-height: 50px;
}

h3 {
  font-size: 27px;
  line-height: 32px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 20px;
  line-height: 30px;
}

h6 {
  font-size: 16px;
  line-height: 26px;
}

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

p {
  margin: 0px;
}

input,
textarea {
  display: inherit;
}

input:focus,
textarea:focus {
  outline: none;
}

img {
  max-width: 100%;
}

/* label {
  padding: 0.5rem 0rem;
} */

.MuiDrawer-paper::-webkit-scrollbar {
  display: none !important;
}

section.data-notfound {
  align-items: center;
  box-shadow: 5px 5px 5px 5px #e4e4e4;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 300px;
  justify-content: center;
}

.css-1191obr-MuiPaper-root-MuiAppBar-root {
  background-color: #fff !important;
}


.css-vwd6ro {
  background-color: #fff !important;
}

.TableCell {
  border: 1px solid black;
}